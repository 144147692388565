var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    [
      _c(
        "v-row",
        { staticClass: "title-bar" },
        [
          _c("page-title", {
            staticClass: "mt-5",
            attrs: {
              "previous-location": "/teachers/courses?tab=package",
              "page-title": !_vm.$language
                ? "Package Details"
                : "প্যাকেজের বিস্তারিত",
            },
          }),
          _c(
            "v-row",
            { staticClass: "mt-3 mr-8", attrs: { justify: "end" } },
            [
              _c(
                "v-btn",
                {
                  staticClass: "edit-btn white--text",
                  attrs: { color: "#0099dc" },
                  on: {
                    click: function ($event) {
                      return _vm.$router.push({
                        name: "package-edit",
                        params: { packageId: _vm.packageId },
                      })
                    },
                  },
                },
                [
                  _c("v-icon", { attrs: { color: "#fff" } }, [
                    _vm._v(" edit "),
                  ]),
                  _vm._v(_vm._s(!_vm.$language ? "Edit" : "এডিট করুন") + " "),
                ],
                1
              ),
              _c(
                "v-btn",
                {
                  staticClass: "white--text",
                  attrs: { color: "#ff5252" },
                  on: {
                    click: function ($event) {
                      return _vm.removePackage(false)
                    },
                  },
                },
                [
                  _c("v-icon", { attrs: { color: "#fff" } }, [
                    _vm._v("delete"),
                  ]),
                  _vm._v(
                    " " + _vm._s(!_vm.$language ? "Delete" : "ডিলিট করুন")
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("dialog-delete", {
        attrs: { deleting: _vm.deleting, "item-text": "Package" },
        on: {
          "confirm-remove": function ($event) {
            return _vm.removePackage(true)
          },
        },
        model: {
          value: _vm.showDialog,
          callback: function ($$v) {
            _vm.showDialog = $$v
          },
          expression: "showDialog",
        },
      }),
      _c(
        "v-card",
        {
          staticClass: "ma-5 pa-5",
          staticStyle: { "border-radius": "10px 10px" },
        },
        [_c("package-details", { attrs: { "package-id": _vm.packageId } })],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }