var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    [
      _vm.isData
        ? _c(
            "v-form",
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    [
                      _c("v-text-field", {
                        attrs: {
                          outlined: "",
                          label: !_vm.$language
                            ? "Package Title"
                            : "প্যাকেজ শিরোনাম",
                          placeholder: !_vm.$language
                            ? "Package name"
                            : "প্যাকেজের নাম",
                          height: "45",
                          readonly: "",
                          value: _vm.packageData.title,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    [
                      _c("h3", [
                        _vm._v(
                          _vm._s(
                            !_vm.$language ? "Added Courses" : "যোগকৃত কোর্স"
                          )
                        ),
                      ]),
                      _c(
                        "v-card",
                        {
                          staticClass: "courses",
                          attrs: { height: "250", outlined: "" },
                        },
                        [
                          _c("div", {
                            staticClass: "ml-2",
                            domProps: { innerHTML: _vm._s(_vm.packageCourses) },
                          }),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    [
                      _c(
                        "span",
                        {
                          staticStyle: {
                            transform: "translateY(-24px) scale(0.75)",
                            "font-size": "16px",
                            color: "#7d7d7d",
                            "background-color": "white",
                            position: "absolute",
                            "margin-top": "12px",
                            "margin-left": "6px",
                            "z-index": "12",
                          },
                        },
                        [
                          _vm._v(
                            _vm._s(
                              !_vm.$language ? "Package image" : "প্যাকেজ ইমেজ"
                            )
                          ),
                        ]
                      ),
                      _c(
                        "v-card",
                        {
                          staticStyle: {
                            display: "flex",
                            "justify-content": "center",
                            "align-items": "center",
                            "border-color": "#c7c7c7",
                          },
                          attrs: { outlined: "", height: "90%", width: "100%" },
                        },
                        [
                          _c("v-img", {
                            staticStyle: { height: "80%", width: "40px" },
                            attrs: { src: _vm.packageData.image },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    [
                      _c("v-text-field", {
                        attrs: {
                          outlined: "",
                          label: !_vm.$language ? "Price" : "মূল্য",
                          height: "45",
                          readonly: "",
                          value: _vm.packageData.price,
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    [
                      _c("v-text-field", {
                        attrs: {
                          outlined: "",
                          label: !_vm.$language
                            ? "Payment type"
                            : "পেমেন্টের ধরন",
                          height: "45",
                          readonly: "",
                          value: _vm.packageData.payment_type,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    [
                      _c("v-text-field", {
                        attrs: {
                          outlined: "",
                          label: !_vm.$language
                            ? "Discount type"
                            : "ডিসকাউন্টের ধরন",
                          height: "45",
                          readonly: "",
                          value: _vm.packageData.discount_type,
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    [
                      _c("v-text-field", {
                        attrs: {
                          outlined: "",
                          label: !_vm.$language ? "Discount" : "ডিসকাউন্ট",
                          height: "45",
                          readonly: "",
                          value: _vm.packageData.discount,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    [
                      _c("v-text-field", {
                        attrs: {
                          outlined: "",
                          label: !_vm.$language ? "From" : "হইতে",
                          height: "45",
                          readonly: "",
                          value: _vm.packageData.start_date,
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    [
                      _c("v-text-field", {
                        attrs: {
                          outlined: "",
                          label: !_vm.$language ? "To" : "পর্যন্ত",
                          height: "45",
                          readonly: "",
                          value: _vm.packageData.expiry_date,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _c("v-progress-circular", { attrs: { color: "primary" } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }