<template>
  <v-container>
    <v-row class="title-bar">
      <page-title
        previous-location="/teachers/courses?tab=package"
        :page-title="!$language ? 'Package Details' : 'প্যাকেজের বিস্তারিত'"
        class="mt-5"
      ></page-title>
      <v-row justify="end" class="mt-3 mr-8">
        <v-btn
          class="edit-btn white--text"
          color="#0099dc"
          @click="
            $router.push({
              name: 'package-edit',
              params: { packageId: packageId }
            })
          "
        >
          <v-icon color="#fff"> edit </v-icon
          >{{ !$language ? "Edit" : "এডিট করুন" }}
        </v-btn>
        <v-btn class="white--text" color="#ff5252" @click="removePackage(false)"
          ><v-icon color="#fff">delete</v-icon>
          {{ !$language ? "Delete" : "ডিলিট করুন" }}</v-btn
        >
      </v-row>
    </v-row>
    <!-- <v-dialog
      v-model="dialog"
      max-width="350"
    >
      <v-card>
        <v-card-title class="text-h5">
          Confirmation!
        </v-card-title>

        <v-card-text>
          Are you sure, you want to delete this package 
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="green darken-1"
            text
            @click="dialog = false"
          >
            No
          </v-btn>

          <v-btn
            color="green darken-1"
            text
            @click="dialog = false"
          >
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog> -->
    <dialog-delete
      v-model="showDialog"
      :deleting="deleting"
      item-text="Package"
      @confirm-remove="removePackage(true)"
    >
    </dialog-delete>
    <v-card class="ma-5 pa-5" style="border-radius: 10px 10px">
      <package-details :package-id="packageId"></package-details>
    </v-card>
  </v-container>
</template>

<script>
import DialogDelete from "../../global/components/DialogDelete.vue";
import PackageDetails from "../../modules/package/PackageDetails.vue";
import PackageService from "#ecf/package/services/PackageService";
export default {
  name: "PackageDetailsPage",
  components: { PackageDetails, DialogDelete },
  props: {
    packageId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      showDialog: false,
      deleting: false
    };
  },

  computed: {
    ownPartnerCode() {
      const access = this.$store.state.user.user.access;

      for (let [partner_code, access_] of Object.entries(access)) {
        if (access_.includes("owner")) return partner_code;
      }
      return "";
    }
  },

  methods: {
    async removePackage(isConfirmed) {
      if (!isConfirmed) {
        this.showDialog = true;
        return;
      }
      try {
        this.deleting = true;
        await PackageService.deletePackage(
          this.ownPartnerCode,
          "pvt",
          this.packageId
        );
        this.$root.$emit("alert", [
          "Success",
          "Successfully deleted the package"
        ]);
        this.showDialog = false;
        this.$router.push({ name: "courses", query: { tab: "package" } });
      } catch (e) {
        this.$root.$emit("alert", [undefined, e.message]);
      } finally {
        this.deleting = false;
      }
    }
  }
};
</script>

<style lang="scss">
.title-bar {
  justify-content: space-between !important;
}
.edit-btn {
  margin-right: 15px;
}
</style>
