<template>
  <v-container>
    <v-form v-if="isData">
      <v-row>
        <v-col>
          <v-text-field
            outlined
            :label="!$language ? 'Package Title' : 'প্যাকেজ শিরোনাম'"
            :placeholder="!$language ? 'Package name' : 'প্যাকেজের নাম'"
            height="45"
            readonly
            :value="packageData.title"
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <!-- <v-text-field
                    outlined
                    label="Added Courses"
                    height="250"
                    readonly
                    :value="packageCourses"
                    >
                    </v-text-field> -->
          <h3>{{ !$language ? "Added Courses" : "যোগকৃত কোর্স" }}</h3>
          <v-card class="courses" height="250" outlined>
            <div class="ml-2" v-html="packageCourses"></div>
          </v-card>
        </v-col>
        <v-col>
          <span
            style="transform: translateY(-24px) scale(0.75);
                        font-size: 16px;
                        color: #7d7d7d;
                        background-color: white;
                        position: absolute;
                        margin-top: 12px;
                        margin-left: 6px;
                        z-index: 12;"
            >{{ !$language ? "Package image" : "প্যাকেজ ইমেজ" }}</span
          >
          <v-card
            outlined
            height="90%"
            width="100%"
            style="display: flex;
                        justify-content: center;
                        align-items: center;
                        border-color: #c7c7c7;"
          >
            <v-img
              style="height: 80%; width: 40px"
              :src="packageData.image"
            ></v-img>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            outlined
            :label="!$language ? 'Price' : 'মূল্য'"
            height="45"
            readonly
            :value="packageData.price"
          >
          </v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            outlined
            :label="!$language ? 'Payment type' : 'পেমেন্টের ধরন'"
            height="45"
            readonly
            :value="packageData.payment_type"
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            outlined
            :label="!$language ? 'Discount type' : 'ডিসকাউন্টের ধরন'"
            height="45"
            readonly
            :value="packageData.discount_type"
          >
          </v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            outlined
            :label="!$language ? 'Discount' : 'ডিসকাউন্ট'"
            height="45"
            readonly
            :value="packageData.discount"
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            outlined
            :label="!$language ? 'From' : 'হইতে'"
            height="45"
            readonly
            :value="packageData.start_date"
          >
          </v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            outlined
            :label="!$language ? 'To' : 'পর্যন্ত'"
            height="45"
            readonly
            :value="packageData.expiry_date"
          >
          </v-text-field>
        </v-col>
      </v-row>
    </v-form>
    <v-progress-circular v-else color="primary"></v-progress-circular>
  </v-container>
</template>

<script>
import PackageService from "./services/PackageService";
export default {
  name: "PackageDetails",
  props: {
    packageId: {
      type: String,
      requied: true
    }
  },
  data() {
    return {
      pkgId: {},
      packageData: null,
      packageCourses: "",
      isData: false
    };
  },

  // async mounted(){
  //     await this.getPackageDetails();
  //     this.getCoursesBatches();
  // },

  computed: {
    ownPartnerCode() {
      const access = this.$store.state.user.user.access;

      for (let [partner_code, access_] of Object.entries(access)) {
        if (access_.includes("owner")) return partner_code;
      }
      return "";
    }
  },

  async created() {
    await this.getPackageDetails();
    this.getCoursesBatches();
  },

  methods: {
    async getPackageDetails() {
      try {
        let partner_code = this.ownPartnerCode;
        let package_type = "pvt";
        let package_id = this.packageId;

        this.packageData = await PackageService.getPackage(
          partner_code,
          package_type,
          package_id
        );
        this.isData = true;
      } catch (error) {
        this.$root.$emit(error, [undefined]);
      }
    },
    getCoursesBatches() {
      let courses = this.packageData.items.courses;

      courses.forEach(course => {
        this.packageCourses =
          this.packageCourses +
          "<b>" +
          course.course_title +
          "</b>" +
          "<br>" +
          "&nbsp;Batches";
        let batches = course.batches;
        this.packageCourses + "<ul style='list-style-type: disc>";
        batches.forEach(batch => {
          this.packageCourses = this.packageCourses + "<li class=ml-8>";
          this.packageCourses = this.packageCourses + batch.batch_title;
          this.packageCourses = this.packageCourses + "</li>";
        });
        this.packageCourses = this.packageCourses + "</ul>";
      });
    }
  }
};
</script>

<style lang="scss">
.courses {
  height: 250px;
  overflow-y: scroll;
  flex-direction: column;
}
</style>
